// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  meetApiHost: "https://api-maqare.kortobaa.net/",
  meetingHost: "https://api-maqare.kortobaa.net/",
  SERVER_PUBLIC_KEY:
    "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAq2AMIcZyOPUJE0qElN1xe1GWFuIzFMDCMsAvuvpQJx2UZDaV3FPF1HMcLSjR9rPHB7F/t4fT9UdSOL+D57c0RVJ0u58arUOxGNnoHDlGp46geJYyYKrNVaDh9U3dZ6IU3a4+qI7H0rqstu6/eyRZnaZDcivOJE5eqi5qhfK6G9gFR25dPw2lp/H41pnWiqEjo68clSoSosZiZPNFa8qowNrhoZJF5/SjVDUMcrwvzkCIwiLhr18N+SEZwn6fke/+rqUkW5FDFED/3Zl12FuJ7Rh8oU8X8s46qHBDr79ogSRaDwucVsOWea9aHUw6s4X9zIypVq2aHlXeafv4XJP48wIDAQAB",
  API_KEY: "3h@jNmC$684nnBujZ!fgHyEwb8Q2@V",
  AES_KEY: "5v8y/B?E(H+MbQeThWmYq3t6w9z$C&F)",
  IV_LENGTH: 16,
  ENC_MODE: "aes-256-cbc",
  ENC_TYPE: "hex",
  version: "1.0",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
